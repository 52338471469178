import React from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {handleLogout} from './Account';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon, Tooltip
} from '@material-ui/core';
import {LogOut as LogoutIcon, Menu as MenuIcon} from 'react-feather';
import Logo from 'src/components/Logo';
import {THEMES} from 'src/constants';
import Account from './Account';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import Popup from './Popup';
import moment from 'moment';
import PhoneIcon from '@mui/icons-material/Phone';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: 64
  }
}));

function TopBar({
                  className,
                  onMobileNavOpen,
                  ...rest
                }) {
  const classes = useStyles();
  const history = useHistory();
  const {isNotify, user} = useSelector(state => state.account);

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon/>
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          
            {isNotify ? <div style={{display: "flex", gap: "20px",fontFamily: "Poppins", alignItems: "center", padding: "10px 0px"}}>
              <div>
          <RouterLink to="/home" style={{color: "#ffffff", textDecoration: "none"}}>
                <Logo/>
          </RouterLink>
              </div>
               <p>
               {moment().format("MMMM DD, YYYY")}
              </p>
              <div style={{width: "1px", backgroundColor: "#ffffff", padding: "10px 0"}}></div>
              <div style={{display: "flex", gap: "8px"}}>
                <PhoneIcon/>
              <a style={{color: "#ffffff", textDecoration: "none"}} href='tel:+508-310-4810'>508-310-4810</a>
              </div>

               </div> :
              <RouterLink to="/home" style={{color: "#ffffff", textDecoration: "none"}}>
                    <Logo/>
              </RouterLink>
                }
        </Hidden> 
        <Box
          ml={2}
          flexGrow={1}
        />
        <Tooltip title="Frequently Asked Questions">
          <IconButton
            color="inherit"
            onClick={() => history.push('/faqs')}
          >
            <SvgIcon style={{fontSize: 28}}>
              <HelpOutlineOutlinedIcon/>
            </SvgIcon>
          </IconButton>
        </Tooltip>
        <Box ml={2}>
          <Account/>
        </Box>
      </Toolbar>
      <Popup/>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
