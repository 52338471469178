import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import {useLocation, useHistory} from "react-router-dom";
import Loader from "react-loader-spinner";
import {useDispatch, useSelector} from 'react-redux';

import RedirectToMobile from "../../components/RedirectToMobile";
import { getInstitutes } from 'src/actions/institutes';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     background: theme.palette.background.linear,
//     display: 'flex',
//     height: '100%',
//     overflow: 'hidden',
//     width: '100%'
//   },
//   wrapper: {
//     display: 'flex',
//     flex: '1 1 auto',
//     overflow: 'hidden',
//     marginTop: 64,
//     [theme.breakpoints.up('lg')]: {
//       paddingLeft: 256
//     }
//   },
//   contentContainer: {
//     display: 'flex',
//     flex: '1 1 auto',
//     overflow: 'hidden'
//   },
//   content: {
//     flex: '1 1 auto',
//     height: '100%',
//     overflow: 'auto'
//   }
// }));

function DashboardLayout({children}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const {isNotify } = useSelector(state => state.account);

  const useStyles = makeStyles((theme) => ({
  root: {
    background: isNotify ? "#ffffff" : theme.palette.background.linear,
     display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    marginTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: isNotify ? "0px" : "256px"
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));
  const classes = useStyles();

  React.useEffect(() => {
    if (location.state) {
      setLoading(location.state.registered);
      setTimeout(() => {
        setLoading(false);
      }, 5000);
      if (!location.state.course_id && !location.state.redirectToInstitutions) {
        history.replace({
          pathname: '/home'
        });
      }
    }
    
    location.state && location.state.isFacebook && window.fbq('track', 'PageView');
  }, [location]);

  React.useEffect( () => {
    dispatch(getInstitutes())
  }, []);

  React.useEffect(()=>{
    setTimeout(() => {
      // document.getElementById('root')?.removeAttribute('style')
      // document.getElementById('dashboard-root')?.removeAttribute('style')
    }, 1000);
  },[children])

  return (
    <React.Fragment>
      {
        loading ? (<div style={{
          background: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          flexDirection: 'column'
        }}>
          <Loader
            type="Puff"
            color="#2872C1"
            height={100}
            width={100}
          />
          <br/>
          <span style={{
          color: '#000',
            fontSize: '20px'
          }}>Please wait while we prepare your Homepage!</span>
        </div>) : ( <div className={classes.root} id='dashboard-root'>
          <TopBar onMobileNavOpen={() => setMobileNavOpen(true)}/>
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>
                {children}
              </div>
            </div>
          </div>
          <RedirectToMobile />
        </div>)
      }

      
    </React.Fragment>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
